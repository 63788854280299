[data-v-0f48239f] .el-checkbox__label {
  padding-left: 2px;
  border-bottom: 1px solid #efefef;
}
.top_select[data-v-0f48239f] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 15px;
}
.div[data-v-0f48239f] {
  width: 100%;
  height: 50px;
  display: flex;
  margin-top: 15px;
  justify-content: center;
}
.z_div[data-v-0f48239f] {
  display: flex;
  width: 1130px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.right_div[data-v-0f48239f] {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.on_val_div[data-v-0f48239f] {
  background-color: #f5f5f5;
  padding-bottom: 10px;
  border-left: 1px solid #efefef;
}
.on_clounm[data-v-0f48239f] {
  display: flex;
  justify-content: end;
  flex-direction: column;
}
.newsdiv[data-v-0f48239f] {
  display: flex;
  flex-direction: column;
}
.left_clounm[data-v-0f48239f] {
  display: flex;
  flex-direction: column;
  border: 1px solid red;
  height: 100%;
  width: 100%;
}
.right_cloum[data-v-0f48239f] {
  display: flex;
  flex-direction: column;
  border: 1px solid green;
  height: 100%;
  width: 100%;
}
.time_div[data-v-0f48239f] {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.time_div p[data-v-0f48239f] {
  color: #fff;
  padding: 2px;
  font-size: 12px;
  background-color: #dadada;
  border-radius: 2px;
}
.dives[data-v-0f48239f] {
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-top: 10px;
  padding-bottom: 10px;
  margin-left: 20px;
  border-radius: 4px;
}
.title_p[data-v-0f48239f] {
  font-size: 15px;
  margin-top: 10px;
  padding-left: 20px;
  font-weight: 550;
}
.val_p[data-v-0f48239f] {
  font-size: 13px;
  padding-left: 20px;
  padding-top: 10px;
  display: flex;
}
.cutu_span[data-v-0f48239f] {
  cursor: pointer;
}
.span_val[data-v-0f48239f] {
  display: inline-block;
  width: 300px;
  word-wrap: break-word;
  font-weight: 520;
  font-family: "Helvetica Neue", sans-serif;
}
.under_div[data-v-0f48239f] {
  border: 1px solid #efefef;
  padding-bottom: 15px;
  height: 250px;
  display: flex;
  justify-content: space-between;
  position: relative;
  border-bottom: none;
}
.under_on_div[data-v-0f48239f] {
  height: 30px;
  display: flex;
  align-items: center;
}
.hint[data-v-0f48239f] {
  font-size: 13px;
  margin-left: 30px;
  font-family: "Helvetica Neue", sans-serif;
  cursor: pointer;
}
.all_div[data-v-0f48239f] {
  display: flex;
  justify-content: space-between;
}
.left_div[data-v-0f48239f] {
  display: flex;
  flex-direction: column;
}
.h_div[data-v-0f48239f] {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.inout[data-v-0f48239f] {
  width: 300px;
  padding-top: 15px;
  margin-left: 10px;
}
.list_left_divactive[data-v-0f48239f] {
  border: 1px solid #f5f5f5;
  border-left: none;
  border-top: none;
  border-bottom: none;
}
.activeEs[data-v-0f48239f] {
  height: 250px;
  border: 1px solid #f5f5f5;
  border-left: none;
  border-right: none;
  border-bottom: none;
}
.list_div[data-v-0f48239f] {
  border: 1px solid #efefef;
  border-radius: 4px;
  width: 300px;
  margin-left: 15px;
  display: flex;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.f_line[data-v-0f48239f] {
  width: 100%;
  height: 3px;
  background-color: black;
}
.span_p[data-v-0f48239f] {
  font-size: 14px;
  margin-left: 20px;
  margin-top: 15px;
  font-family: monospace;
  font-weight: 550;
}
.div_box[data-v-0f48239f] {
  display: flex;
  align-items: center;
}
.flex_image_div[data-v-0f48239f] {
  display: flex;
  width: 30px;
  height: 30px;
  margin-left: 20px;
  margin-top: 10px;
}
.image_box[data-v-0f48239f] {
  width: 100%;
  padding-left: 10px;
  padding-top: 10px;
  height: 100%;
}
.image_box2[data-v-0f48239f] {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.name_span[data-v-0f48239f] {
  font-size: 13px;
  margin-left: 10px;
}
.active[data-v-0f48239f] {
  display: flex;
  width: 70px;
  height: 60px;
  margin-left: 20px;
  margin-top: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 3px;
  overflow: hidden;
}
.newactive[data-v-0f48239f] {
  display: flex;
  width: 70px;
  margin-left: 20px;
  margin-top: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 3px;
  overflow: hidden;
  height: 100%;
}
.series[data-v-0f48239f] {
  display: flex;
  flex-direction: column;
}
.button_div[data-v-0f48239f] {
  width: 70px;
  margin-top: 10px;
  margin-left: 20px;
}
.add_class[data-v-0f48239f] {
  margin-left: 40px;
}
.right_dives[data-v-0f48239f] {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.btn_button[data-v-0f48239f] {
  width: 50px;
  height: 50px;
  display: flex;
  background-color: #17a2b8;
  border-radius: 50%;
  color: #fff;
  font-size: 13px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 10px;
}
.btn_button span[data-v-0f48239f] {
  margin-left: 7px;
  font-size: 12px;
}
.right_div_buttondiv[data-v-0f48239f] {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-left: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.footer_div[data-v-0f48239f] {
  display: flex;
  justify-content: flex-end;
  width: 1100px;
  padding-right: 20px;
}
.title_div[data-v-0f48239f] {
  display: flex;
  justify-content: center;
  height: 21px;
  margin-top: 3px;
}
.underdiv[data-v-0f48239f] {
  width: 100%;
  height: 500px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.header_div[data-v-0f48239f] {
  display: flex;
  justify-content: space-between;
}
.left_screen[data-v-0f48239f] {
  height: 500px;
  width: 300px;
  border: 1px solid #e7e6e6;
}
.screen_header[data-v-0f48239f] {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
}
.time[data-v-0f48239f] {
  font-size: 14px;
  font-weight: bold;
}
.righhtdiv[data-v-0f48239f] {
  display: flex;
  align-items: center;
}
.righhtdiv img[data-v-0f48239f] {
  width: 20px;
  height: 20px;
}
.key_div[data-v-0f48239f] {
  width: 300px;
  height: 50px;
  background: #fff;
  position: absolute;
  bottom: 30px;
  display: flex;
  align-items: center;
  border: 1px solid #edeaea;
  border-right: none;
  border-left: none;
  border-bottom: none;
}
.add_menu[data-v-0f48239f] {
  border: 2px dashed rgba(7, 193, 96, 0.6);
  height: 48px;
  width: 250px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1px;
  cursor: pointer;
}
.add_div[data-v-0f48239f] {
  width: 250px;
  margin-left: 20px;
  height: 48px;
  /* border: 1px solid; */
  display: flex;
  align-items: center;
  border: 1px solid #e7e6e6;
  border-top: none;
  border-left: none;
  border-bottom: none;
  margin-right: 1px;
}
.lines[data-v-0f48239f] {
  width: 1px;
  height: 25px;
  background-color: #e7e6e6;
}
.add_div_box[data-v-0f48239f] {
  width: 100%;
  height: 50px;
  border: 1px solid #e7e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.divActive[data-v-0f48239f] {
  background-color: rgba(7, 193, 96, 0.05);
  border: 1px solid #07c160;
  color: #07c160;
  position: relative;
  margin-bottom: -14px;
}
.add_div_box span[data-v-0f48239f] {
  font-size: 13px;
}
.val_span[data-v-0f48239f] {
  color: #07c160;
  font-size: 14px;
  margin-left: 5px;
}
.right_screen[data-v-0f48239f] {
  margin-left: 20px;
  margin-top: 5px;
}
.no_menu_div[data-v-0f48239f] {
  background-color: #fff;
  width: 750px;
  height: 160px;
  border-radius: 10px;
  box-shadow: 0 2px 10px #ddd;
  padding-top: 30px;
}
.tit_span[data-v-0f48239f] {
  margin-left: 70px;
}
.line_div[data-v-0f48239f] {
  width: 750px;
  height: 1px;
  background-color: #c7c6c6;
  margin-top: 40px;
}
.menu_underdiv[data-v-0f48239f] {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20px;
}
.btn_el[data-v-0f48239f] {
  background-color: #07c160;
  color: #fff;
}
.dad_div[data-v-0f48239f] {
  background-color: #fff;
  width: 760px;
  height: 200px;
  border-radius: 10px;
  box-shadow: 0 2px 10px #ddd;
  padding-top: 30px;
}
.dad_div h1[data-v-0f48239f] {
  margin-left: 20px;
}
.input_div[data-v-0f48239f] {
  display: flex;
  align-items: center;
}
.child_div[data-v-0f48239f] {
  width: 100%;
  height: 300px;
  margin-bottom: 250px;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.child_list[data-v-0f48239f] {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.triangle[data-v-0f48239f] {
  width: 30px;
  height: 30px;
}
.sj[data-v-0f48239f] {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 10px solid red;
  transform: rotate(45deg);
}
.child_box[data-v-0f48239f] {
  width: 100%;
  height: 40px;
  border: 1px solid #e7e7eb;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.childclass[data-v-0f48239f] {
  margin-bottom: 0;
  border-bottom: none;
  border: 1px solid #e7e7eb;
}
.button_div_bottom[data-v-0f48239f] {
  position: absolute;
  width: 780px;
  bottom: 30px;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}
.contextmenu[data-v-0f48239f] {
  margin: 0;
  background: #fff;
  z-index: 2147483647;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
  position: fixed;
}
.contextmenu li[data-v-0f48239f] {
  margin: 0;
  padding: 7px 16px;
  cursor: pointer;
}
.contextmenu li[data-v-0f48239f]:hover {
  background: #eee;
}
.addclassname[data-v-0f48239f] {
  display: none;
}
.heng[data-v-0f48239f] {
  display: flex;
  align-items: center;
}
.shu[data-v-0f48239f] {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.newshu[data-v-0f48239f] {
  position: relative;
  top: 13px;
}
.com_style[data-v-0f48239f] {
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: #999;
  margin-top: 5px;
}
.com_style2[data-v-0f48239f] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: #999;
}
.nick_name[data-v-0f48239f] {
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  color: #333;
}
.actived[data-v-0f48239f] {
  background-color: #f5f5f5;
}
.dives_left[data-v-0f48239f] {
  background-color: #95ec69;
  width: 300px;
}
.activedSpan[data-v-0f48239f] {
  color: var(--themeColor, #17a2b8);
}
.left_fixed[data-v-0f48239f] {
  width: 50px;
  background-color: #f5f5f5;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  position: relative;
  text-align: center;
}
[data-v-0f48239f] .el-checkbox {
  display: flex;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.main[data-v-0f48239f] {
  display: flex;
}
[data-v-0f48239f] .el-scrollbar {
  height: auto;
}
.main_right[data-v-0f48239f] {
  border-left: 1px solid #b9b9b9;
}
.span_class[data-v-0f48239f] {
  width: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dialog_btn_div[data-v-0f48239f] {
  display: flex;
  align-items: center;
}
.textarea[data-v-0f48239f] .el-textarea__inner {
  height: 160px;
  overflow-y: auto;
  outline: none;
  border: none;
  resize: none;
  box-shadow: none;
  width: 720px;
}
.first_textarea[data-v-0f48239f] .el-textarea__inner {
  height: 85px;
  overflow-y: auto;
  outline: none;
  resize: none;
  width: 700px;
}
.second_textarea[data-v-0f48239f] {
  height: 60px;
  overflow-y: auto;
  outline: none;
  resize: none;
  width: 700px;
}
.fxk_div[data-v-0f48239f] {
  width: 100%;
}
.big_box[data-v-0f48239f] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -20px;
}
.box[data-v-0f48239f] {
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-top: 20px;
}
.choose_img[data-v-0f48239f] {
  border-radius: 50px;
}
.list_list[data-v-0f48239f] {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.divList[data-v-0f48239f] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.da_loucm[data-v-0f48239f] {
  display: flex;
  align-items: center;
  width: 120px;
  height: 60px;
  overflow: hidden;
}
.loucmn[data-v-0f48239f] {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loucmn img[data-v-0f48239f] {
  border-radius: 50px;
}
.line_bind[data-v-0f48239f] {
  width: 600px;
  height: 1px;
  background-color: #e5e2e2;
}
.no_bind[data-v-0f48239f] {
  width: 100%;
  height: calc(100vh - 96px);
  position: relative;
  display: flex;
  align-items: center;
  display: none;
}
.main_left[data-v-0f48239f] {
  width: 45%;
  height: 100%;
  background-image: linear-gradient(to right, #eff9fb, #fefeff);
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_left img[data-v-0f48239f] {
  width: 90%;
}
.main_rights[data-v-0f48239f] {
  float: left;
}
.main_rights h3[data-v-0f48239f] {
  margin-bottom: 15px;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: 600;
}
.main_rights h5[data-v-0f48239f] {
  margin-bottom: 10px;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 600;
}
.pics[data-v-0f48239f] {
  width: 150px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.pics img[data-v-0f48239f] {
  width: 100%;
}
.each_contents[data-v-0f48239f] {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.each_contents .el-icon[data-v-0f48239f] {
  font-size: 20px;
  color: #72dd88;
  margin-right: 3px;
}
.each_contents p[data-v-0f48239f] {
  color: #474747;
  font-size: 14px;
}